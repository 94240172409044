<template>
  <div class="col-sm-12 ml-3">
    <div class="row mb-3">
      <div class="col-sm-8 border border-light py-2">
        <div class="row">
          <div class="col-sm-3 pr-1">
            <multiselect
              v-model="pisoSalaSelected"
              :options="listaPisoSala"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Seleccione Piso/Sala"
              :searchable="true">
              <span slot="caret" v-if="!(pisoSalaSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
          <div class="col-sm-3 px-1">
            <multiselect
              v-model="camaSelected"
              :options="listaCamasFiltered"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Seleccione Cama"
              :searchable="true">
              <span slot="caret" v-if="!(camaSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
          <div class="col-sm-3 px-1">
            <multiselect
              v-model="claseSelected"
              :options="listaClases"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Seleccione Clase"
              :searchable="true">
              <span slot="caret" v-if="!(claseSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
          <div class="col-sm-2 pt-1">
            <vue-numeric
              v-model="importe"
              class="form-control"
              separator=","
              :precision="2"
              placeholder=" "
            />
          </div>
          <div class="col-sm-1 pl-0 pr-1">
            <button
              class="btn btn-success btn-block px-1"
              @click="agregarModificarItem"
              :disabled="inputDisabled"
            >
              {{textButtonForm}}
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-1 pl-2 pr-0 pt-2">
        <label for="hora_salida" class="col-form-label text-right">
          <strong>Hora de Salida:</strong>
        </label>
      </div>
      <div class="col-sm-2 pt-2">
        <div class="row col-sm-10 pl-2">
          <b-input-group>
            <input
              id="hora_salida"
              v-model="horaSalida"
              type="time"
              class="form-control text-center"
            >
          <b-input-group-append>
            <button
              class="btn btn-light px-2"
              @click="saveHoraSalida"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-floppy"
                viewBox="0 0 16 16"
              >
                <path d="M11 2H9v3h2z"/>
                <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16
                  2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0
                  0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5
                  0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0
                  0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5
                  7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0
                  .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5
                  0 0 0-.5.5z"
                />
              </svg>
            </button>
          </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-8 px-0">
        <b-table
          class="table table-striped table-hover"
          :busy="isLoading"
          :fields="fields"
          :items="listaSalaCama"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(editar)="items">
            <i
              @click="editarItem(items.item)"
              class="nav-icon i-Pen-5 text-success font-weight-bold"
              style="cursor: pointer"
            ></i>
          </template>
          <template #cell(eliminar)="items">
            <i
              @click="onClickEliminarIcon(items.item)"
              v-b-modal.modal-eliminar
              class="nav-icon i-Close-Window text-danger font-weight-bold"
              style="cursor: pointer"
            ></i>
          </template>
          <template #cell(importe_x_dia)="items">
            {{items.item.importe_x_dia | numeral('0,0.00')}} {{simboloMonedaPrincipal}}
          </template>
        </b-table>
        <b-modal id="modal-eliminar"
          :title="'Seguro que quieres eliminar el Registro - N° '+selectedNro+'?'"
          hide-footer
        >
          <div class="row">
            <div class="col-sm-6">
              <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
                Eliminar
              </button>
            </div>
            <div class="col-sm-6">
              <button class="col-sm-12 btn btn-outline-dark"
              @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'SalaCama',
  components: {
    Multiselect,
    VueNumeric,
  },
  watch: {
    listaSalaCama() {
      this.updateComboCama();
    },
  },
  data() {
    return {
      pisoSalaSelected: null,
      listaPisoSala: [],
      camaSelected: null,
      listaCamas: [],
      listaCamasFiltered: [],
      claseSelected: null,
      listaClases: [],
      inputDisabled: false,
      textButtonForm: 'Agregar',
      isLoading: true,
      fields: [
        {
          key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'numero', label: 'ID', thStyle: { width: '6%' },
        },
        {
          key: 'piso_sala_nombre', label: 'Piso/Sala', thStyle: { width: '24%' },
        },
        {
          key: 'cama_nombre', label: 'Cama', thStyle: { width: '21%' },
        },
        {
          key: 'clase_nombre', label: 'Clase', thStyle: { width: '24%' },
        },
        {
          key: 'importe_x_dia', label: 'Importe por Día', thStyle: { width: '15%' },
        },
      ],
      listaSalaCama: [],
      modoEdicion: false,
      selectedId: null,
      selectedNro: null,
      horaSalida: '',
      simboloMonedaPrincipal: '',
      importe: 0,
    };
  },
  async created() {
    await this.cargarDatosIniciales();
    this.listarSalaCamas();
  },
  methods: {
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/parametros/sala_camas/create');
        this.listaPisoSala = RESPONSE.data.data.piso_salas;
        this.listaCamas = RESPONSE.data.data.camas;
        this.listaClases = RESPONSE.data.data.clases;
        this.horaSalida = RESPONSE.data.data.hora_salida ?? '';
        this.simboloMonedaPrincipal = RESPONSE.data.data.moneda_principal.simbolo;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async listarSalaCamas() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/parametros/sala_camas');
        this.listaSalaCama = RESPONSE.data.data;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    updateComboCama() {
      let listaTemporal = [...this.listaCamas];
      this.listaSalaCama.forEach((item) => {
        listaTemporal = listaTemporal.filter((el) => el.id !== item.cama_id);
      });
      this.listaCamasFiltered = listaTemporal;
    },
    updateComboCamaFromEditar() {
      if (this.modoEdicion === true) {
        let listaTemporal = [...this.listaCamas];
        this.listaSalaCama.forEach((item) => {
          if (item.id === this.selectedId) {
            listaTemporal = listaTemporal.filter((el) => (
              el.id !== item.cama_id || this.selectedId === item.id
            ));
          } else {
            listaTemporal = listaTemporal.filter((el) => el.id !== item.cama_id);
          }
        });
        this.listaCamasFiltered = listaTemporal;
      }
    },
    async guardarDatos() {
      const REQUEST = {
        piso_sala_id: this.pisoSalaSelected.id,
        cama_id: this.camaSelected.id,
        clase_id: this.claseSelected.id,
        importe_x_dia: this.importe,
      };
      let estado = false;
      try {
        const RESPONSE = await axios.post('/clinic/parametros/sala_camas', REQUEST);
        await this.listarSalaCamas();
        util.showNotify(RESPONSE.data.message, 'success');
        estado = true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return estado;
    },
    async actualizarItem() {
      const REQUEST = {
        piso_sala_id: this.pisoSalaSelected.id,
        cama_id: this.camaSelected.id,
        clase_id: this.claseSelected.id,
        importe_x_dia: this.importe,
      };
      let estado = false;
      try {
        const URL = `/clinic/parametros/sala_camas/${this.selectedId}`;
        const response = await axios.put(URL, REQUEST);
        await this.listarSalaCamas();
        util.showNotify(response.data.message, 'success');
        estado = true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return estado;
    },
    async saveHoraSalida() {
      const REQUEST = {
        hora_salida_sala_cama: this.horaSalida,
      };
      try {
        const RESPONSE = await axios.post('/clinic/parametros/sala_camas/store_hora_salida', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    activarModoEdicion() {
      this.modoEdicion = true;
      this.textButtonForm = 'Modificar';
    },
    desactivarModoEdicion() {
      this.modoEdicion = false;
      this.textButtonForm = 'Agregar';
    },
    agregarModificarItem() {
      if (this.pisoSalaSelected === null) {
        util.showNotify('Elije un Piso/Sala.', 'warn');
        return;
      }
      if (this.camaSelected === null) {
        util.showNotify('Elije una Cama.', 'warn');
        return;
      }
      if (this.claseSelected === null) {
        util.showNotify('Elije una Clase.', 'warn');
        return;
      }
      this.inputDisabled = true;
      if (this.modoEdicion) {
        if (this.actualizarItem()) {
          this.pisoSalaSelected = null;
          this.camaSelected = null;
          this.claseSelected = null;
          this.importe = 0;
          this.selectedId = null;
          this.desactivarModoEdicion();
        }
      } else {
        this.selectedId = null;
        this.selectedNro = null;
        if (this.guardarDatos()) {
          this.pisoSalaSelected = null;
          this.camaSelected = null;
          this.claseSelected = null;
          this.importe = 0;
        }
      }
      this.inputDisabled = false;
    },
    cargarComboPisoSalaById(id) {
      if (id === null) {
        return;
      }
      this.pisoSalaSelected = this.listaPisoSala.find((el) => el.id === id);
    },
    cargarComboCamaById(id) {
      if (id === null) {
        return;
      }
      this.camaSelected = this.listaCamas.find((el) => el.id === id);
    },
    cargarComboClaseById(id) {
      if (id === null) {
        return;
      }
      this.claseSelected = this.listaClases.find((el) => el.id === id);
    },
    async getDataToEdit() {
      try {
        const RESPONSE = await axios.get(`/clinic/parametros/sala_camas/${this.selectedId}/edit`);
        const PISO_SALA_ID = RESPONSE.data.data.piso_sala_id;
        const CAMA_ID = RESPONSE.data.data.cama_id;
        const CLASE_ID = RESPONSE.data.data.clase_id;
        this.importe = RESPONSE.data.data.importe_x_dia;
        this.cargarComboPisoSalaById(PISO_SALA_ID);
        this.cargarComboCamaById(CAMA_ID);
        this.cargarComboClaseById(CLASE_ID);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    editarItem(item) {
      this.activarModoEdicion();
      this.selectedId = item.id;
      this.selectedNro = null;
      this.getDataToEdit();
      this.updateComboCamaFromEditar();
    },
    setItems(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.numero;
    },
    onClickEliminarIcon(item) {
      if (this.modoEdicion) {
        this.pisoSalaSelected = null;
        this.camaSelected = null;
        this.claseSelected = null;
        this.importe = 0;
        this.updateComboCama();
        this.desactivarModoEdicion();
      }
      this.setItems(item);
    },
    eliminarRegistroLocalmente() {
      this.listaSalaCama = this.listaSalaCama.filter((item) => (
        item.id !== this.selectedId
      ));
    },
    async eliminarRegistro() {
      this.$bvModal.hide('modal-eliminar');
      try {
        this.eliminarRegistroLocalmente();
        const RESPONSE = await axios.delete(`/clinic/parametros/sala_camas/${this.selectedId}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarSalaCamas();
      }
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div>
    <div class="breadcrumb">
      <h1 class="mb-0"><strong>Parametros</strong></h1>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-10">
                <button
                  class="btn mr-3"
                  :class="isEstructuraConsultorio? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'EstructuraConsultorio'">
                  Estructura Consultorio
                </button>
                <button
                  class="btn mr-3"
                  :class="isDiagnosticoCie ? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'DiagnosticoCie'"
                >
                  Diagnóstico CIE
                </button>
                <button
                  class="btn mr-3"
                  :class="isArancel ? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'Arancel'"
                >
                  Arancel
                </button>
                <button
                  class="btn mr-3"
                  :class="isOtros ? 'th-custom-color text-dark': 'btn-white'"
                  @click="componentShow = 'Otros'"
                >
                  Otros
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-9" v-if="isEstructuraConsultorio">
                <div class="row">
                  <button
                    class="btn border border-light col-sm-2 mx-3"
                    :class="isCrear ? 'btn-light text-dark': 'btn-white'"
                    @click="optionSelected = 'Crear'"
                  >
                    <b>Crear</b>
                  </button>
                  <button
                    class="btn border border-light col-sm-2 mr-3"
                    :class="isAsignar ? 'btn-light text-dark': 'btn-white'"
                    @click="optionSelected = 'Asignar'"
                  >
                    <b>Asignar</b>
                  </button>
                </div>
              </div>
            </div>
            <Crear v-if="isEstructuraConsultorio && isCrear"/>
            <Asignar v-if="isEstructuraConsultorio && isAsignar"/>
            <TheTabClasificacionCie v-if="isDiagnosticoCie"/>
            <TheTabArancel v-if="isArancel"/>
            <TheTablCategoriaLinae v-if="isCategoriaLinae"/>
            <TheOtros v-if="isOtros" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Crear from '../components/TheCrear.vue';
import Asignar from '../components/TheAsignar.vue';
import TheTablCategoriaLinae from '../components/TheTabCategoriaLinae.vue';
import TheTabClasificacionCie from '../components/TheTabClasificacionCie.vue';
import TheTabArancel from '../components/TheTabArancel.vue';
import TheOtros from '../components/TheOtros.vue';

export default {
  name: 'index',
  components: {
    Crear,
    Asignar,
    TheTablCategoriaLinae,
    TheTabClasificacionCie,
    TheTabArancel,
    TheOtros,
  },
  data() {
    return {
      componentShow: 'EstructuraConsultorio',
      optionSelected: 'Crear',
    };
  },
  computed: {
    isEstructuraConsultorio() {
      return this.componentShow === 'EstructuraConsultorio';
    },
    isCrear() {
      return (this.optionSelected === 'Crear');
    },
    isAsignar() {
      return (this.optionSelected === 'Asignar');
    },
    isDiagnosticoCie() {
      return (this.componentShow === 'DiagnosticoCie');
    },
    isArancel() {
      return (this.componentShow === 'Arancel');
    },
    isCategoriaLinae() {
      return (this.componentShow === 'CategoriaLinae');
    },
    isOtros() {
      return (this.componentShow === 'Otros');
    },
  },
};
</script>

<style scoped>
</style>
